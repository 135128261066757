<template>
  <b-row>
    <b-col cols="12">
      <b-card  class="d-flex" no-body>
        <b-card-header>
          <div>
            <b-card-title class="analytics-title">{{$t('campaigns.contentDistribution') }}</b-card-title>
          </div>
        </b-card-header>

        <b-card-body>
          <app-echart-scatter :option-data="option" :key="change_graph" ref="scatter_graph" class="w-full d-flex"/>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody
} from 'bootstrap-vue'
import utils from '@/libs/utils'
import AppEchartScatter from '@core/components/charts/echart/AppEchartScatter.vue'

export default {
  name: 'ScatterAnalytics',
  props: {
    influencers: {
      type: Array,
      default: () => []
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    AppEchartScatter
  },
  data() {
    return {
      utils,
      change_graph: false,
      option: {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const date = utils.getDatev2(new Date(params[0].data[0]))
            let cad = `${date}<br>`
            params.forEach(param => {
              cad += `${param.marker} ${param.seriesName}: ${param.data[2]}<br>`
            })
            return cad
          },
          axisPointer: {
            type: 'line',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        xAxis: {
          type:'category',
          axisLabel: {
            formatter: (value) => {
              const date = utils.getDatev2(new Date(value))
              return `${date}`;
            }
          }
        },
        yAxis: {
          show: false
        },
        series: []
      },
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.formatContents()
    const self = this
    setTimeout(() => {
      self.$refs.scatter_graph.$refs.line.chart.on('click', params => {
        this.clickPointer(params)
      })
    }, 500)
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.change_graph = !this.change_graph;
    },
    clickPointer(params) {
      const content = params.data[3]
      const url = this.getUrl(content)
      window.open(url, '_blank')
    },
    getUrl(content) {
      if (content.url_info) return content.url_info
      else return content.image_content
    },
    formatContents() {
      const data = [
        {
          name: this.$t('influencerCategory.top-celebrity'),
          id: 'top-celebrity',
          symbolSize: 10,
          data: [],
          type: 'scatter',
          weight: 50,
          itemStyle: {
            color: '#42CFE8',
          },
        },
        {
          name: this.$t('influencerCategory.top-influencer-celebrity'),
          id: 'top-influencer-celebrity',
          symbolSize: 10,
          data: [],
          type: 'scatter',
          weight: 40,
          itemStyle: {
            color: '#EA5456',
          },
        },
        {
          name: this.$t('influencerCategory.macroinfluencer'),
          id: 'macroinfluencer',
          symbolSize: 10,
          data: [],
          type: 'scatter',
          weight: 30,
          itemStyle: {
            color: '#7367F0',
          },
        },
        {
          name: this.$t('influencerCategory.microinfluencer'),
          id: 'microinfluencer',
          symbolSize: 10,
          data: [],
          type: 'scatter',
          weight: 20,
          itemStyle: {
            color: '#F99F43',
          },
        },
        {
          name: this.$t('influencerCategory.nanoinfluencer'),
          id: 'nanoinfluencer',
          symbolSize: 10,
          data: [],
          type: 'scatter',
          weight: 10,
          itemStyle: {
            color: '#43C76F',
          },
        },
      ];

      const contents = []

      this.influencers.forEach(influencer => {
        influencer.content.forEach(content => {
          if (content.published_date) contents.push({content, influencer: influencer.influencer})
        })
      })

      contents.sort((content1, content2) => {
        return new Date(content1.content.published_date).getTime() - new Date(content2.content.published_date).getTime()
      });

      contents.forEach(content => {
        const has_url = this.checkUrlPublish(content.content)
        if (content.content.published_date && has_url) {
          const date_instance = new Date(content.content.published_date)
          date_instance.setDate(date_instance.getDate() + 1);
          const serie = data.find(item => item.id === content.influencer.calculate_category)
          const x = Math.floor(Math.random() * 11) * serie.weight
          serie.data.push([date_instance.getTime(), x, content.influencer.name, content.content])
        }
      })

      this.option.series = data
      this.change_graph = !this.change_graph
    },
    checkUrlPublish(content) {
      return content.url_info || content.image_content
    }
  },
  watch: {
    '$i18n.locale'() {
      this.formatContents()
    }
  } 
}
</script>

<style>
  .w-full {
    width: 100%;
  }
</style>
