<template>
  <b-row>
    <b-col class="col-12 text-right">
      <b-button
        variant="export-button-table"
        class="mb-1 export-button-table"
        @click="exportSelected()"
        :disabled="exportable.length < 1"
      >
        <feather-icon icon="ShareIcon"/>
        {{$t('campaigns.export')}}
      </b-button>

      <b-button
        variant="danger"
        class="mb-1 ml-1"
        @click="bulkRemove()"
        :disabled="exportable.length < 1"
      >
        {{$t('delete')}}
      </b-button>
    </b-col>
    <b-card class="col-12">
      <vue-good-table
        :columns="columns"
        :rows="info"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'category', type: 'asc'},
          multipleColumns: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-cell-click="onCellClick"
        @on-selected-rows-change="selectionChanged"
        class="vgt-table-contents"
      >
        <template slot="table-column" slot-scope="props">
          <div v-if="props.column.field === 'influencer'" class="cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'category'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'network'" class="d-block text-center cursor-pointer">
          {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'followers'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'status'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'content_type'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
            <div v-if="props.column.field === 'created_at'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
            <div v-if="props.column.field === 'number_of_impressions'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'engagement'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <span v-if="props.column.field === 'updated_at'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </span>
          <span v-if="props.column.field === 'actions'" class="d-block text-center">
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'influencer'" class="text-nowrap">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <div class="container-avatar-table">
                  <b-avatar
                    size="md"
                    :src="getImage(props.row)"
                    :text="props.row.username"
                    variant="light-primary"
                  />
                  <div class="container-avatar-table-icon contents-icon">
                    <span :class="`${utils.getClassNetworkSpan(props.row.network_influencer)} mr-1 style-border-icon`" />
                  </div>
                </div>
              </template>

              <a class="d-block font-weight-bold mb-0 name-link" :href="`${local}/perfil/${props.row.network_influencer}/${props.row.username}/`" target="_blank">
                {{ getUsername(props.row) }}
                <image-verified v-if="props.row.is_verified" class="iconCheck"/>
              </a>
            </b-media>
          </span>
          <span v-else-if="props.column.field === 'category'" class="text-nowrap d-block text-center p-2">
            <span class="text-nowrap">{{$t(utils.getTypeInfluencerTraduction(props.row.category))}}</span>
          </span>
          <span v-else-if="props.column.field === 'network'" class="text-nowrap d-block text-center">
            <span class="text-nowrap">
              <b-img class="margin-icon" :src="`${utils.getImageNetworkColor(props.row.network.toLowerCase())}`"/>
              {{ utils.capitalize(props.row.network)}}
            </span>
          </span>
          <span v-else-if="props.column.field === 'followers'" class="text-nowrap d-block text-center">
            <span class="text-nowrap">{{props.row.followers}}</span>
          </span>
          <span v-else-if="props.column.field === 'status'" class="text-nowrap d-block text-center">
            <span class="text-nowrap">{{$t(`campaigns.statuses.${props.row.status}`)}}</span>
          </span>
          <span v-else-if="props.column.field === 'content_type'" class="text-nowrap d-block text-center">
            <span class="text-nowrap">
              <b-icon class="content-type" :icon="`${utils.getContent(props.row.content_type)}`"/>
              {{getTypeContent(props.row)}}
            </span>
          </span>
          <span v-else-if="props.column.field === 'created_at'" class="text-nowrap d-block text-center">
            <span class="text-nowrap">{{getDatePublish(props.row)}}</span>
          </span>
          <span v-else-if="props.column.field === 'number_of_impressions'" class="text-nowrap d-block text-center">
            <p v-show="props.row.number_of_impressions == null" class="text-center mb-0"> - </p>
            <p v-show="props.row.number_of_impressions != null" class="text-center mb-0">{{utils.separatebycomma(props.row.number_of_impressions)}}</p>          </span>
          <span v-else-if="props.column.field === 'engagement'" class="text-nowrap d-block text-center">
            <span v-if="props.row.engagement == null || props.row.engagement === 0">-</span>
            <span v-else class="text-nowrap">{{props.row.engagement}}%</span>
          </span>
          <span v-else-if="props.column.field === 'updated_at'" class="text-nowrap d-block text-center">
            <span class="text-nowrap">{{utils.getDatev2(new Date(props.row.updated_at))}}</span>
          </span>
          <span v-else-if="props.column.field === 'actions'" class="d-block text-center">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropright
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item  v-if="props.row.status !== 'content_pending'" :href="getUrl(props.row)" target="blank">
                  <feather-icon icon="EyeIcon" class="mr-50"/>
                  <span>{{$t('campaigns.watch_content')}}</span>
                </b-dropdown-item>
                <b-dropdown-item hidden v-if="props.row.status === 'content_published' && props.row.content_type === 'story'" @click="downloadWithVueResource(props.row.image_content)">
                  <feather-icon icon="DownloadIcon" class="mr-50"/>
                  <span>{{$t('campaigns.download_content')}}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="addContent(props.row)">
                  <feather-icon icon="PlusCircleIcon" class="mr-50" />
                  <span>{{$t('campaigns.extra_content')}}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="removeContent(props.row.uuid)">
                  <feather-icon icon="Trash2Icon" class="mr-50"/>
                  <span>{{$t('campaigns.delete_content')}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{$t('campaigns.showing_to')}} </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '50', '100']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{$t('campaigns.of')}} {{ props.total }} {{$t('campaigns.entries')}}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) => props.pageChanged({ currentPage: value })
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <sidebar-content
      v-if="Object.keys(content).length !== 0"
      :show_side="show_side"
      :content_dad="content"
      :campaign_influencer="campaign_influencer"
      :campaign_uuid="campaign_uuid"
      @updateFromSidebar="updateFromSidebar"
      :key="change_sidebar"
      class="open-sidebar-contents"
    />

    <form-add-content
      :show_form_metrics="false"
      @contentAdded="contentAdded" 
      :campaign_influencer="campaign_influencer_to_add" 
      :show_add_content="show_add_content"
      :content_published="content_published"
      @update_header="update_header"
      :key="key_modal_add"
    />

  </b-row>
</template>

<script>
import { 
  BCard, 
  BMedia, 
  BAvatar, 
  BCol, 
  BRow, 
  BDropdown, 
  BDropdownItem, 
  BPagination,
  BFormSelect, 
  BButton, 
  BImg
} from 'bootstrap-vue';
import { VueGoodTable } from "vue-good-table";
import service from "@/services/campaign";
import utils from '@/libs/utils';
import sidebarContent from './sidebarContent'
import FormAddContent from './FormAddContent.vue';
import manage_campaign from '@/services/manage_campaign'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageVerified from '@/views/components/image/ImageVerified.vue';


export default {
  name: 'publishContent',
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    ImageVerified,
    BImg,
    BCard, 
    BCol, 
    BRow, 
    BMedia, 
    BAvatar, 
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    FormAddContent,
    BButton,
    sidebarContent
  },
  data() {
    return {
      content_published: false,
      total_contents_update: 0,
      total_contents_published_update: 0,
      local: window.location.origin,
      pageLength: 10,
      columns: [
        {
          label: "lists.influencer",
          field: "influencer",
        },
        {
          label: "campaigns.type_influencer",
          field: "category",
          sortable: true,
          sortFn: this.sortFn,
        },
        {
          label: "settings.social.network",
          field: "network",
        },
        {
          label: "campaigns.followers",
          field: "followers",
        },
        {
          label: "campaigns.status",
          field: "status",
        },
        {
          label: "briefcase_pricing.content_type",
          field: "content_type",
        },
        {
          label: "campaigns.date_publish",
          field: "created_at",
        },
        {
          label: "number_of_impressions",
          field: "number_of_impressions",
          type: 'number',
        },
        {
          label: "campaigns.erTitle",
          field: "engagement",
          type: 'number',
        },
        {
          label: "campaigns.last_update",
          field: "updated_at",
        },
        {
          label: "campaigns.actions",
          field: "actions",
        },
      ],
      parent_contents: [],
      service,
      utils,
      info: [],
      show_modal: false,
      show_side: false,
      content: {},
      campaign_influencer: '',
      campaign_uuid: '',
      show_add_content: false,
      key_modal_add: false,
      campaign_influencer_to_add: '',
      exportable: [],
      change_sidebar: 0
    }
  },
  beforeCreate () {
    this.$vs.loading();
    service.getCampaignManage(this.$route.params.uuid).then((response) => {
      this.info = this.formatPublished(response.results);
      this.campaign_uuid = this.$route.params.uuid;
      this.$vs.loading.close();
    })
  },
  methods: {
    update_header(data) {
      this.$emit('update_header', data);
    },
    sortFn(x, y) {
      const first = utils.getTypeInfluencerNumber(x)
      const second = utils.getTypeInfluencerNumber(y)
      return (first < second ? -1 : (first > second ? 1 : 0));
    },
    getUrl(content) {
      if (content.url_info) return content.url_info
      return content.image_content
    },
    getTypeContent(content) {
      if (content.network === 'tiktok' && content.content_type === 'reel') return 'TikTok'
      if (content.network === 'blog' && content.content_type === 'post') return 'Blog Post'
      if (content.network === 'snapchat' && content.content_type === 'reel') return 'Spotlight'
      return this.$t(`invitation.${content.content_type}`)
    },
    copyLink(data) {
      const url = `${window.location.host}/external-new/details/${this.slug}/${data.network_influencer}/${data.username}`;
      this.$copyText(url).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.copied'),
              icon: "BellIcon",
              text: this.$t('campaigns.clipboard'),
              variant: 'success'
            },
          });
        },
        (e) => {
          console.log(e);
        }
      );
    },
    removeContent(uuid) {
      this.$swal({
        title: this.$t('campaigns.textConfirmContent'),
        text: this.$t('campaigns.textContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmContent'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        heightAuto: false,
      }).then(result => {
        if (result.value) {
          const content = this.info.find(item => item.uuid === uuid)
          const parent = this.parent_contents.find(item => item.uuid === content.parent_uuid)

          manage_campaign.deleteBulkContent(this.$route.params.uuid, [uuid]).then(() => {
            const full = this.fullContentsPublished([uuid])[0].published
            if (full && parent.status !== 'published') {
              this.updateStatusParent(parent.uuid)

              service.influencerAction(this.$route.params.uuid, [content.parent_uuid], 'published')
                .then((response) => {
                  this.$emit('update_header', response.results[0].campaign_header);
                })
              this.info = this.info.filter((item) => item.uuid !== uuid);
            } else {
              this.info = this.info.filter((item) => item.uuid !== uuid);
            }
          });
        }
      })
    },
    downloadWithVueResource (url_info) {
      this.$http.get(url_info, {
        headers: {
          'Access-Control-Allow-Origin': 'Get',
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Headers':'*'
        }
      }, {
        responseType: 'arraybuffer'
      })
        .then(response => {
          this.forceFileDownload(response,url_info)  
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('campaigns.error_status'),
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: this.$t('campaigns.error_status_download')
            }
          });
        })
    },
    forceFileDownload (response,url_info) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      if (utils.isVideo(url_info)) {
        link.setAttribute('download', 'story.mp4')
      } {
        link.setAttribute('download', 'story.jpg')
      }
      document.body.appendChild(link)
      link.click()
    },
    bulkRemove() {
      const content_uuids = [];
      this.exportable.forEach(item => {
        content_uuids.push(item.uuid)
      })

      this.$swal({
        title: this.$t('campaigns.textConfirmContent'),
        text: this.$t('campaigns.textContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmContent'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          const response = this.fullContentsPublished(content_uuids)
          response.forEach(result => {
            const parent = this.parent_contents.find(item => item.uuid === result.uuid)
            if (result.published && parent.status !== 'published') {
              this.updateStatusParent(parent.uuid)
              service.influencerAction(this.$route.params.uuid, [parent.uuid], 'published')
                .then((response) => {
                  this.$emit('update_header', response.results[0].campaign_header);
                })
            }
          })
          manage_campaign.deleteBulkContent(this.$route.params.uuid, content_uuids).then(() => {
            this.info = this.info.filter((item) => content_uuids.indexOf(item.uuid) < 0);
          })
        }
      })
    },
    addContent(content) {
      this.content_published = content.status_parent === 'published'
      this.campaign_influencer_to_add = content.campaing_influencer
      this.show_add_content = true
      this.key_modal_add = !this.key_modal_add
    },
    getDatePublish(content) {
      if (!content.published_date) return '-'
      const date_instance = new Date(content.published_date)
      date_instance.setDate(date_instance.getDate() + 1);
      return utils.getDatev2(new Date(date_instance))
    },
    getStatus(status) {
      return status
    },
    contentAdded() {
      this.$vs.loading();
      service.getCampaignManage(this.$route.params.uuid).then((response) => {
        this.info = this.formatPublished(response.results);
        this.campaign_uuid = this.$route.params.uuid;
        this.$vs.loading.close();
      })
    },
    formatPublished(influencers) {
      const data = [];
      influencers.forEach(influencer => {
        const obj = {
          'uuid': influencer.uuid,
          'status': influencer.status,
          'contents': influencer.content,
          'username': influencer.influencer.username
        }
        this.parent_contents.push(obj)
        influencer.content.forEach((content) => {
          content['username'] = influencer.influencer.username;
          content['category'] = influencer.influencer.calculate_category;
          content['image'] = influencer.influencer.profile_url;
          content['followers'] = utils.getFormat(influencer.influencer.followers);
          content['engagement'] = utils.decimalFixed(influencer.influencer.engagement_rate, 2),
          content['profile_url'] = influencer.influencer.profile_url;
          content['file_for_profile'] = influencer.influencer.file_for_profile;
          content['network_influencer'] = influencer.influencer.network.toLowerCase();
          content['name'] = influencer.influencer.name;
          content['is_verified'] = influencer.influencer.is_verified
          content['status_parent'] = influencer.status
          content['parent_uuid'] = influencer.uuid
          content['parent_network'] = influencer.influencer.network
          data.push(content)
        })
      })
      return data;
    },
    updateStatusParent(uuid) {

      const new_array_parents = this.parent_contents.map(item => {
        if (item.uuid === uuid) {
          return {...item, status: 'published'};
        }

        return item;
      });

      this.parent_contents = new_array_parents
    },
    fullContentsPublished(uuids) {
      const parents_uuids = []
      uuids.forEach(uuid => {
        const con = this.info.find(item => item.uuid === uuid)

        if (con) {
          parents_uuids.push(con.parent_uuid)
        }
      })

      const parents = this.parent_contents.filter(item => parents_uuids.indexOf(item.uuid) > -1) 

      const result = []
      parents.forEach(parent => {
        const new_contents = parent.contents.filter(item => uuids.indexOf(item.uuid) < 0);

        const new_array_parents = this.parent_contents.map(item => {
          if (item.uuid === parent.uuid) {
            return {...item, contents: new_contents};
          }

          return item;
        });

        this.parent_contents = new_array_parents

        const new_parent = new_array_parents.find(item => item.uuid === parent.uuid)

        if (new_parent.contents.length < 1) {
          const res = {
            'uuid': parent.uuid,
            'published': false
          }

          result.push(res)
        } else {
          let published = 0;
          new_parent.contents.forEach(item => {
            if (item.url_info !== null) {
              published += 1
            } else if (item.image_content !== null) published += 1
          });

          const res = {
            'uuid': parent.uuid,
            'published': published === new_parent.contents.length
          }

          result.push(res)
        }
      })

      return result
    },
    onCellClick(data) {
      if (data.column.field !== 'actions') {
        this.show_side = true;
        this.content = data.row;
        this.campaign_influencer = data.row.campaing_influencer;
        this.change_sidebar += 1;
      }
    },
    selectionChanged(data) {
      this.exportable = data.selectedRows
    },
    getImage(influencer) {
      if (influencer.file_for_profile)
        return utils.getAssetUrl(influencer.file_for_profile);
      else 
        return influencer.profile_url;
    },
    getUsername(influencer) {
      if (influencer.network_influencer === "youtube") return influencer.name;
      return influencer.username;
    },
    updateFromSidebar(data) {
      if (data !== undefined) {
        const result = this.info.find(item => item.uuid === data.uuid)
        result.status = data.status;
        result.url_info = data.url_info;
        result.number_of_impressions = data.number_of_impressions;
        result.updated_at = data.updated_at;
        result.published_date = data.published_date
        result.number_of_comments = data.number_of_comments
        result.number_of_likes = data.number_of_likes
        result.number_of_prints = data.number_of_prints
        result.number_of_shares = data.number_of_shares
        result.number_of_times_saved = data.number_of_times_saved;
        if (data.image_content) {
          if (utils.isVideo(data.image_content)) {
            result.content_preview_url = data.content_preview_url
          }
          result.image_content = data.image_content
        }
      } 
      this.show_side = false;
      this.change_sidebar += 1;
    },
    exportSelected() {
      this.$vs.loading();
      const rows = [
        [
          'Influencer',
          'Tipo de influencer',
          'Red social',
          'Seguidores',
          'Estatus',
          'Tipo de contenido',
          'Fecha publicación',
          'Impresiones',
          'Likes',
          'Comentarios',
          'Shares',
          'saves',
          'Última fecha de actualización'
        ]
      ];
      this.exportable.forEach(data => {
        rows.push([
          data.username,
          utils.getTypeInfluencer(data.category),
          utils.capitalize(data.network),
          data.followers,
          this.$t(`campaigns.statuses.${data.status}`),
          this.$t(data.content_type),
          data.published_date,
          data.number_of_impressions > 0 ? data.number_of_impressions : '-',
          data.number_of_likes > 0 ? data.number_of_likes : '-',
          data.number_of_comments > 0 ? data.number_of_comments : '-',
          data.number_of_shares > 0 ? data.number_of_shares : '-',
          data.number_of_times_saved > 0 ? data.number_of_times_saved : '-',
          utils.getDatev2(new Date(data.updated_at)),
        ])
      })

      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = "Influencers publicados.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      const self = this
      setTimeout(() => {
        self.$vs.loading.close();
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
  .margin-icon{
    width: 20px !important;
  }
.vgt-table-contents {
  overflow-x: scroll;
}
.per-page-selector {
  width: 90px;
}
.name-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.name-link {
  color: black;
  z-index: 100;
}
.icon-margin-right {
  margin: 0 0.3em 0 0
}
.container-avatar-table {
  position: relative;
}
.container-avatar-table-icon {
  position: absolute; 
  bottom: -5px; 
  right: -15px;
}
.style-border-icon {
  font-size:1em; 
  border-radius: 50%; 
  background: white; 
  padding: 2px
}
.contents-icon {
  font-size: 12px;
}
.sidebar-icon {
  font-size: 18px;
  margin-right: 10px;
}
.content-type{
  font-size: 15px;
  margin-right: 10px;
  color: #7367F0;
}
.open-sidebar-contents {
  animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
</style>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
