<template>
  <b-row class="match-height">
    <b-col md="12">
      <b-card
        class="card-transaction"
        no-body
      >
        <b-card-header>
          <b-card-title class="analytics-title"> {{ $t('campaigns.totalsAnalytics') }}</b-card-title>
          <b-button class="mt-1" variant="success" @click="dowloadCsv">
            {{ $t('campaigns.export') }}
            <b-icon
              font-scale="1"
              icon="file-earmark-excel"
              >
            </b-icon>
          </b-button>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              md="4"
              class="mb-2"
              v-for="(metric, key) in counters"
              :key="key"
            >
              <div
                class="transaction-item"
              >
                <b-media
                  no-body
                  class="align-items-center"
                >
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      :variant="getVariant(key)"
                    >
                      <b-icon
                        font-scale="1.7"
                        :icon="getIcon(key)"
                      >

                      </b-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title" v-if="key !== 'engagements'">
                      {{$t(key)}}
                    </h6>
                    <h6 class="transaction-title" v-else>
                      {{$t('campaigns.engagementsTotal')}}
                    </h6>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder"
                >
                {{ utils.numberFormat(metric) }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

import {BRow, BCol, BCard, BAvatar, BMediaAside, BCardHeader, BCardBody, BCardTitle, BMedia, BMediaBody, BButton, BIcon} from 'bootstrap-vue'
import utils from '@/libs/utils'
import service from "@/services/campaign";

export default {
  props: {
    counters: {
      required: true
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMediaAside,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaBody,
    BButton,
    BIcon
  },
  computed: {
  },
  data () {
    return {
      utils,
      change_graph: false,
      series: [],
      labels: [],
      contents: [],
      total: 0
    }
  },
  beforeCreate() {
    service.getCampaignManage(this.$route.params.uuid).then((response) => {
      this.contents = this.formatPublished(response.results)
    });
  },
  created () {
  },
  methods: {
    formatPublished(influencers) {
      const data = [];
      influencers.forEach(influencer => {
        influencer.content.forEach((content) => {
          content['username'] = influencer.influencer.username;
          content['category'] = influencer.influencer.calculate_category;
          content['image'] = influencer.influencer.profile_url;
          content['followers'] = influencer.influencer.followers;
          content['engagement'] = utils.decimalFixed(influencer.influencer.engagement_rate, 2),
          content['profile_url'] = influencer.influencer.profile_url;
          content['file_for_profile'] = influencer.influencer.file_for_profile;
          content['network_influencer'] = influencer.influencer.network.toLowerCase();
          content['name'] = influencer.influencer.name;

          data.push(content)
        })
      })
      return data;
    },
    dowloadCsv() {
      this.$vs.loading();
      const rows = [
        [
          'Influencer',
          'Tipo de influencer',
          'Red social',
          'Seguidores',
          'Estatus',
          'Tipo de contenido',
          'Fecha publicación',
          'Impresiones',
          'Likes',
          'Comentarios',
          'Shares',
          'Saves',
          'Última fecha de actualización'
        ]
      ];
      this.contents.forEach(data => {
        rows.push([
          data.username,
          utils.getTypeInfluencer(data.category),
          utils.capitalize(data.network),
          data.followers,
          this.$t(`campaigns.statuses.${data.status}`),
          this.$t(data.content_type),
          data.published_date,
          data.number_of_impressions > 0 ? data.number_of_impressions : '-',
          data.number_of_likes > 0 ? data.number_of_likes : '-',
          data.number_of_comments > 0 ? data.number_of_comments : '-',
          data.number_of_shares > 0 ? data.number_of_shares : '-',
          data.number_of_times_saved > 0 ? data.number_of_times_saved : '-',
          utils.getDatev2(new Date(data.updated_at)),
        ])
      })

      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = "Influencers publicados.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      const self = this
      setTimeout(() => {
        self.$vs.loading.close();
      }, 200)
    },
    getVariant(name_metric) {
      let variant = 'light-primary';
      switch (name_metric) {
      case 'scope_number':
        variant = 'light-primary';
        break;
      case 'number_of_likes':
        variant = 'light-success';
        break;
      case 'number_of_comments':
        variant = 'light-warning';
        break;
      case 'number_of_shares':
        variant = 'light-danger';
        break;
      case 'number_of_times_saved':
        variant = 'light-info';
        break;
      case 'number_of_interactions':
        variant = 'light-primary';
        break;
      case 'number_of_impressions':
        variant = 'light-success';
        break;
      case 'number_of_prints':
        variant = 'light-primary';
        break;
      case 'number_of_views':
        variant = 'light-warning';
        break;
      case 'number_of_responses':
        variant = 'light-danger';
        break;
      case 'number_of_clicks_to_the_link':
        variant = 'light-info';
        break;
      case 'number_of_clicks_to_stickers':
        variant = 'light-info';
        break;
      case 'number_of_dislikes':
        variant = 'light-primary';
        break;
      case 'number_of_rts':
        variant = 'light-success';
        break;
      case 'number_of_times_favs':
        variant = 'light-warning';
        break;
      case 'number_of_reproductions':
        variant = 'light-danger';
        break;
      }
      return variant
    },
    getIcon(name_metric) {
      let icon = 'SmartphoneIcon';
      switch (name_metric) {
      case 'scope_number':
        icon = 'SmartphoneIcon';
        break;
      case 'number_of_likes':
        icon = 'heart-fill';
        break;
      case 'number_of_comments':
        icon = 'chat';
        break;
      case 'number_of_shares':
        icon = 'box-arrow-up-right';
        break;
      case 'number_of_times_saved':
        icon = 'bookmark';
        break;
      case 'number_of_interactions':
        icon = 'HeartIcon';
        break;
      case 'number_of_impressions':
        icon = 'graph-up';
        break;
      case 'number_of_prints':
        icon = 'SmartphoneIcon';
        break;
      case 'number_of_views':
        icon = 'EyeIcon';
        break;
      case 'number_of_responses':
        icon = 'CornerDownLeftIcon';
        break;
      case 'number_of_clicks_to_the_link':
        icon = 'MousePointerIcon';
        break;
      case 'number_of_clicks_to_stickers':
        icon = 'SmileIcon';
        break;
      case 'number_of_dislikes':
        icon = 'ThumbsDownIcon';
        break;
      case 'number_of_rts':
        icon = 'RepeatIcon';
        break;
      case 'number_of_times_favs':
        icon = 'StarIcon';
        break;
      case 'number_of_reproductions':
        icon = 'PlayIcon';
        break;
      case 'engagements':
        icon = 'emoji-heart-eyes';
        break;
      }
      return icon
    },
  }
}
</script>
