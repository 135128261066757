<template>
  <b-card no-body>
    <b-card-header>
      
      <b-card-title class="analytics-title" id="avaible-instagram">
        {{$t('instagram.ageRange')}}
      </b-card-title>
      <b-tooltip target="avaible-instagram">{{$t('campaigns.avaible_instagram')}}</b-tooltip>
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            {{$t('instagram.female')}}

          </b-card-text>
          <h3 class="font-weight-bolder">
            <span style="color:#E75480">{{ percentage_female }}%</span>
          </h3>
        </div>
        <div class="mr-2">
          <b-card-text class="mb-50">
            {{$t('instagram.male')}}

          </b-card-text>
          <h3 class="font-weight-bolder">
            <span style="color:#5cb3ff">{{ percentage_male }}%</span>
          </h3>
        </div>
      </div>
      <vue-apex-charts :key="change_graph" type="line" height="200" :options="chartOptions" :series="series" class="cursor-pointer"></vue-apex-charts>
    </b-card-body>
  </b-card>
</template>

<script>

import { BCard, BCardBody, BCardHeader, BCardTitle, BCardText, BTooltip  } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import utils from '@/libs/utils'

export default {
  props: {
    ages_gender: {
      type: Array,
      required: true
    },
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    VueApexCharts,
    BTooltip
  },
  data () {
    return {
      utils,
      categories: [],
      series: [{name: `${this.$t('instagram.male')}`, data: []}, {name: `${this.$t('instagram.female')}`, data: []}],
      percentage_male: 0,
      percentage_female: 0,
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: `${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${utils.decimalFixed(val, 2)}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      change_graph: false
    }
  },
  created () {
    if (this.ages_gender.length > 0) {
      this.chartOptions.xaxis.categories = this.ages_gender[0].by_age_group.map(item => {
        return item.group.replace('age', '')
      })

      this.ages_gender.forEach((item, index) => {
        this.series[index].data =  item.by_age_group.map(item => { return item.value })
        this.series[index].data.pop()
      })

      this.percentage_male = utils.numberFormat(utils.decimalFixed(this.ages_gender[0].value, 2));
      this.percentage_female = utils.numberFormat(utils.decimalFixed(this.ages_gender[1].value, 2));
      const self = this
      setTimeout(function() {
        self.change_graph = true
      }, 750);
    }
  },
  methods: {
  },
  watch: {
    '$i18n.locale'() {
      this.change_graph = !this.change_graph;
      this.series[0].name =  `${this.$t('instagram.male')}`;
      this.series[1].name =  `${this.$t('instagram.female')}`;
    }
  } 
}
</script>

